// import api from "@/api";

import axios from "@/axios";

const getters = {
    wheelData(state) {
        return state.wheelData;
    },
};

const state = {
    wheelData: false,
    loading: false,
};

export const SET_WHEEL_DATA = "setWheelData";
export const SET_WHEEL_DATA_LOADING = "setWheelDataLoading";
const mutations = {
    [SET_WHEEL_DATA](state, payload) {
        state.wheelData = payload;
    },
    [SET_WHEEL_DATA_LOADING](state, payload) {
        state.loading = payload;
    },
};

export const GET_WHEEL_DATA = "getWheelData";
const actions = {
    [GET_WHEEL_DATA](context) {
        if (!context.state.loading) {
            context.commit(SET_WHEEL_DATA_LOADING, true);
            return new Promise((resolve, reject) => {
                axios
                    .post("/wheel.active", {
                        token: context.getters.token,
                    })
                    .then((response) => {
                        // console.log(response.headers["content-type"]);
                        if (
                            response.headers["content-type"] ===
                            "text/html; charset=UTF-8"
                        ) {
                            console.log("OPS..");
                            setTimeout(() => {
                                context.commit(SET_WHEEL_DATA_LOADING, false);
                                context.dispatch(GET_WHEEL_DATA).then(resolve);
                            }, 500);
                        } else {
                            context.commit(SET_WHEEL_DATA, response.data);
                            context.commit(SET_WHEEL_DATA_LOADING, false);
                            resolve(response.data);
                        }
                    })
                    .catch((data) => {
                        context.commit(SET_WHEEL_DATA_LOADING, false);
                        reject(data.response);
                    });
            });
        }
    },
};

export default {
    getters,
    state,
    mutations,
    actions,
};
