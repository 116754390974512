import { createStore } from "vuex";
import wheel from "@/store/modules/wheel";
import client from "@/store/modules/client";
import prediction from "@/store/modules/prediction";
import dataset from "@/store/modules/dataset";
import timer from "@/store/modules/timer";

export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        client,
        wheel,
        prediction,
        dataset,
        timer,
    },
});
