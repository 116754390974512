// import api from "@/api";

const getters = {
    token(state) {
        return state.token;
    },
};

const state = {
    token: false,
};

export const SET_TOKEN = "setToken";
const mutations = {
    [SET_TOKEN](state, payload) {
        state.token = payload;
    },
};

const actions = {};

export default {
    getters,
    state,
    mutations,
    actions,
};
