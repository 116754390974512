import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

require("@tabler/core/dist/js/tabler.min");
require("@tabler/core/dist/css/tabler.min.css");

require("../../app/src/styles/_packages.scss");

// require("@tabler/core/dist/css/tabler-vendors.min.css");

import ws from "@/websocket";

const app = createApp(App);
app.use(store).use(router);
app.config.globalProperties.$ws = ws;
app.mount("#app");
