import Echo from "laravel-echo";
// import axios from "axios";
window.io = require("socket.io-client");

if (typeof io !== "undefined") {
    window.Echo = new Echo({
        broadcaster: "socket.io",
        host: window.location.hostname + ":8443",
        // encrypted: false,
        // disableStats: true,
        // enabledTransports: ["ws", "wss"], // <-- only use ws and wss as valid transports
    });
}

const prefix = "streamer_database_";
const prefixPrivate = "streamer_database_private-";
// let activeConnections = [];

const publicChannel = (channel) => {
    return {
        listen: (method, callback = null) => {
            window.Echo.channel(prefix + channel).listen(
                "." + method,
                (response) => {
                    if (callback !== null) callback(response.event);
                }
            );
            console.log(
                "[PUBLIC] %c" +
                    channel +
                    ":" +
                    method +
                    " - started listening.",
                "font-weight: 600; color: green;"
            );
        },
        leave: () => {
            window.Echo.leave(prefix + channel);
            console.log(
                "[PUBLIC] %c" + channel + " - stopped listening.",
                "font-weight: 600; color: red;"
            );
        },
    };
};

const privateChannel = (channel) => {
    return {
        listen: (method, callback = null) => {
            window.Echo.channel(prefixPrivate + channel).listen(
                "." + method,
                (response) => {
                    if (callback !== null) callback(response.event);
                }
            );
            console.log(
                "[PRIVATE] %c" +
                    channel +
                    ":" +
                    method +
                    " - started listening.",
                "font-weight: 600; color: green;"
            );
        },
        leave: () => {
            window.Echo.leave(prefixPrivate + channel);
            console.log(
                "[PRIVATE] %c" + channel + " - stopped listening.",
                "font-weight: 600; color: red;"
            );
        },
    };
};

export default {
    publicChannel,
    privateChannel,
};
