import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    // {
    //     path: "/about",
    //     name: "about",
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    // },
    // {
    //     path: "/prediction",
    //     name: "prediction",
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "prediction" */ "../views/TwitchPrediction.vue"
    //         ),
    // },
    {
        path: "/prediction",
        name: "prediction",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "prediction" */ "../widgets/Prediction"
            ),
    },
    {
        path: "/dataset",
        name: "dataset",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "dataset" */ "../widgets/Dataset"),
    },
    {
        path: "/widgets/twitch/prediction",
        name: "widgets_twitch_prediction",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "upgrade" */ "../views/Upgrade.vue"),
    },
    {
        path: "/wheel",
        name: "wheel",
        component: () =>
            import(/* webpackChunkName: "wheel" */ "../widgets/Wheel"),
    },
    {
        path: "/timer",
        name: "timer",
        component: () =>
            import(/* webpackChunkName: "timer" */ "../widgets/Timer"),
    },
];

const router = createRouter({
    history: createWebHistory("/"),
    routes,
});

export default router;
