// import api from "@/api";

import axios from "@/axios";

const getters = {
    prediction(state) {
        return state.prediction;
    },
};

const state = {
    prediction: false,
};

export const SET_PREDICTION = "setPrediction";
const mutations = {
    [SET_PREDICTION](state, payload) {
        state.prediction = payload;
    },
};

export const GET_LAST_PREDICTION = "getLastPrediction";
const actions = {
    [GET_LAST_PREDICTION](context) {
        return new Promise((resolve, reject) => {
            axios
                .post("/prediction.active", {
                    token: context.getters.token,
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
};

export default {
    getters,
    state,
    mutations,
    actions,
};
